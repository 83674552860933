<template>
  <v-container>
    <v-row class="ma-4" justify="center">
      <v-col cols="12" md="8" class="px-8">
        <v-list-item-title
          style="font-size: 20px; font-weight: 700; color: gray"
          class="mb-4"
        >
          Detalles de la membresía
        </v-list-item-title>
        <p style="font-size: 15px; color: gray">
          <span v-html="decode"></span>
        </p>

        <v-img :src="flyer" width="1500" height="auto" v-if="flyer" contain>
        </v-img>

        <iframe
          class="rounded-lg my-3"
          width="100%"
          height="350"
          :src="fixVideo"
          v-if="video != ''"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </v-col>
      <v-col cols="8" md="4">
        <v-row>
          <v-card
            rounded="xl"
            elevation="0"
            outlined
            :style="`border: 1px solid ${primaryColor}`"
            class="mx-auto"
            width="100%"
          >
            <v-card-title
              class="pb-2 font-weight-bold text-h5"
              :style="`color: ${primaryColor}`"
            >
              Asociación
            </v-card-title>
            <v-row class="pa-2">
              <v-col cols="12" class="d-flex justify-center py-0">
                <v-img
                  class="rounded-xl"
                  max-width="130"
                  height="auto"
                  :src="organization.profilePicture"
                ></v-img>
              </v-col>
              <v-col v-if="organization" cols="12">
                <p class="mb-0 font-weight-bold subtitle-1 text-center">
                  {{ organization.company }}
                </p>
                <p class="text-center mb-0">
                  {{ emailContact }}
                </p>
              </v-col>
              <v-col v-if="socialMedia" cols="12" class="mb-4">
                <v-row class="d-flex justify-center">
                  <v-btn
                    v-if="socialMedia.facebook != ''"
                    icon
                    :href="socialMedia.facebook"
                    target="_blank"
                  >
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="socialMedia.instagram != ''"
                    icon
                    :href="socialMedia.instagram"
                    target="_blank"
                  >
                    <v-icon>mdi-instagram</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="socialMedia.twitter != ''"
                    icon
                    :href="socialMedia.twitter"
                    target="_blank"
                  >
                    <v-icon>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="socialMedia.linkedin != ''"
                    icon
                    :href="socialMedia.linkedin"
                    target="_blank"
                  >
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row class="my-8">
          <v-card
            rounded="xl"
            elevation="0"
            outlined
            v-bind:style="`border: 1px solid ${primaryColor}`"
            class="mx-auto"
            width="100%"
          >
            <v-row class="px-2">
              <v-card-title
                class="pb-2 font-weight-bold text-h5"
                :style="`color: ${primaryColor}`"
              >
                Patrocinadores
              </v-card-title>
              <v-col cols="12" class="d-flex justify-center">
                <v-row justify="center">
                  <v-col
                    cols="6"
                    md="4"
                    xl="3"
                    class="d-flex justify-center pa-5"
                    v-for="sponsor in sponsors"
                    :key="sponsor._id"
                  >
                    <v-img
                      style="cursor: pointer"
                      @click="goToPage(sponsor.url)"
                      class="rounded-lg align-self-center sponsor-img"
                      :src="sponsor.logo"
                      max-width="70"
                      height="auto"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="faqs && faqs.length > 0" class="my-5">
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
        class="px-6 px-md-2 pb-0 pt-5 d-flex align-center"
      >
        <p
          class="headline font-weight-bold mb-1 mt-4"
          v-bind:style="`color: ${primaryColor}`"
        >
          Preguntas frecuentes
        </p>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1" class="px-6 px-md-2">
        <v-expansion-panels multiple flat>
          <v-expansion-panel
            v-for="item in faqs"
            :key="item.id"
            class="rounded-xl my-2"
            style="background: #f0f0f0"
          >
            <v-expansion-panel-header>
              <span class="subtitle-1 font-weight-bold">{{
                item.question
              }}</span>
              <template v-slot:actions>
                <v-icon> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: '',
    },
    video: {
      type: String,
      default: '',
    },
    organization: {
      type: Object,
      default: {},
    },
    socialMedia: {
      type: Object,
      default: {},
    },
    primaryColor: {
      type: String,
      default: '',
    },
    emailContact: {
      type: String,
      default: '',
    },
    sponsors: {
      type: Array,
      default: () => [],
    },
    faqs: {
      type: Array,
      default: () => [],
    },
    flyer: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      images: [
        'https://picsum.photos/200/300',
        'https://picsum.photos/200/400',
        'https://picsum.photos/200/500',
        'https://picsum.photos/200/600',
        'https://picsum.photos/200/700',
        'https://picsum.photos/200/800',
      ],
    };
  },

  methods: {
    goToPage(tab) {
      if (tab != '') window.open(tab);
    },
  },
  computed: {
    decode() {
      // No intenten entenderlo, solo gozenlo
      return decodeURIComponent(
        window
          .atob(this.description)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
    },
    fixVideo() {
      let vlink = this.video;
      if (!vlink.includes('watch?v=') && vlink.includes('.be/'))
        vlink = vlink.replace('.be/', 'be.com/watch?v=');
      vlink =
        vlink.includes('watch?v=') && vlink.includes('&t=')
          ? vlink.replace('watch?v=', 'embed/').replace('&t=', '?start=')
          : vlink.replace('watch?v=', 'embed/');
      return vlink;
    },
  },
};
</script>
<style scoped></style>
